import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

export type EndpointCache = {
  url: string;
  ttl: number;
};

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cacheEndpoints: EndpointCache[] = [
    {
      url: '/api/User/current?',
      ttl: 30000,
    },
    {
      url: '/api/User/currentUserIP',
      ttl: 60000,
    },
  ];

  private cache: Map<string, HttpResponse<any>> = new Map();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    if (!this.hasCacheEnabled(req)) {
      return next.handle(req);
    }

    if (req.headers.get('Cache-Control') === 'no-cache') {
      this.cache.delete(req.url);
      return next.handle(req);
    }

    const cachedResponse = this.getCache(req.url);

    if (cachedResponse) {
      return of(cachedResponse.clone());
    } else {
      return next.handle(req).pipe(
        tap((stateEvent) => {
          if (stateEvent instanceof HttpResponse) {
            this.setCache(req, stateEvent.clone());
          }
        }),
      );
    }
  }

  getCacheSettings(url: string): EndpointCache {
    return this.cacheEndpoints.find((ce) => url.includes(ce.url));
  }

  hasCacheEnabled(req: HttpRequest<any>): boolean {
    return this.getCacheSettings(req.url) != null;
  }

  getCache(url: string): HttpResponse<any> | null {
    return this.cache.get(url);
  }

  setCache(req: HttpRequest<any>, res: HttpResponse<any>): void {
    if (!this.hasCacheEnabled(req)) {
      return;
    }

    const ttl = this.getCacheSettings(req.url)?.ttl;
    if (!ttl) {
      return;
    }

    this.cache.set(req.url, res.clone());
    setTimeout(() => {
      this.cache.delete(req.url);
    }, ttl);
  }
}
