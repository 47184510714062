import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as Domain from '../../domain';
import { api_default_limit, api_default_page } from '../api.constants';

@Injectable({
  providedIn: 'root',
})
export class LayerApiService {
  constructor(private http: HttpClient) {}

  private readonly layer_api_path = '/light/api/layer';

  getLayerName(id: string): Observable<Domain.RequestResponse> {
    const headers = new HttpHeaders({
      id: id,
    });
    return this.http.get<Domain.RequestResponse>(`${this.layer_api_path}/getName`, { headers: headers });
  }

  updatePropertyVisibility(tag: any): Observable<Domain.User> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.layer_api_path}/visibility`, tag)
      .pipe(map((res) => res.data));
  }

  updateDefaultPropertyVisibility(tag: any): Observable<Domain.User> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.layer_api_path}/defaultVisibility`, tag)
      .pipe(map((res) => res.data));
  }

  deleteDefaultPropertie(tag: any): Observable<Domain.Tags> {
    const headers = new HttpHeaders({ id: tag._id });
    return this.http
      .delete<Domain.RequestResponse>(`${this.layer_api_path}/defaultProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  listFavoriteLayers(
    groupName: string,
    {
      type,
      offset = 0,
      limit,
      light,
      desc,
      orderBy = 'Priority',
    }: {
      type?: Domain.LayerType | Domain.LayerType[];
      offset?: number;
      limit?: number;
      light?: string;
      desc?: boolean;
      orderBy?: string;
    } = {},
  ): Observable<Domain.RequestResponse> {
    const query: any = [{ criteria: '$eq', property: 'FavoritedByGroupNames', value: groupName }];

    return this.listLayers(type, offset, JSON.stringify(query), limit, light, desc, orderBy);
  }

  listLayers(
    type?: Domain.LayerType | Domain.LayerType[],
    offset: number = 0,
    query?: string,
    limit: number = api_default_limit,
    light?: string,
    desc?: boolean,
    orderBy?: string,
    {
      folderId,
      haveAFolder,
      isDefault,
    }: {
      folderId?: string;
      haveAFolder?: boolean;
      isDefault?: boolean;
    } = {},
    getLocalAndShared?: boolean,
    getRemoveFromList?: boolean,
  ): Observable<Domain.RequestResponse> {
    let headers =
      type === Domain.LayerType.Remote
        ? new HttpHeaders({})
        : new HttpHeaders({
            offset: String(offset),
            limit: String(limit),
          });
    headers = headers.append('forceListMSAObjects', 'true');

    if (orderBy) headers = headers.append('orderby', String(orderBy));

    if (type) {
      if (type === Domain.LayerType.Remote) {
        headers = headers.append('shared', 'true');
      } else {
        headers = headers.append('layerType', Array.isArray(type) ? type.join(',') : type);
      }
    }

    if (query) {
      headers = headers.append('query', query);
    }

    if (light) headers = headers.append('light', light);
    if (desc !== undefined) headers = headers.append('desc', String(desc));

    if (folderId) headers = headers.append('folderId', folderId);

    if (getLocalAndShared) headers = headers.append('getLocalAndShared', 'true');

    if (getRemoveFromList) headers = headers.append('getRemoveFromList', 'true');

    if (haveAFolder !== undefined) headers = headers.append('haveAFolder', String(haveAFolder));

    if (isDefault !== undefined) headers = headers.append('isDefault', String(isDefault));

    return this.http.get<Domain.RequestResponse>(`${this.layer_api_path}`, { headers: headers });
  }

  getLayers(
    type?: Domain.LayerType,
    offset: number = 0,
    limit: number = api_default_limit,
    light?: string,
  ): Observable<Domain.Layer[]> {
    return this.listLayers(type, offset, undefined, limit, light).pipe(
      map((res) => {
        return res.data.docs as Domain.Layer[];
      }),
    );
  }

  listByMSAObject(MSAObject_id: any) {
    let headers = new HttpHeaders();
    headers = headers.append('MSAObject_id', MSAObject_id);

    return this.http
      .get<Domain.RequestResponse>(`${this.layer_api_path}/listByMSAObject`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Layer[]));
  }

  listSituationLayers(
    situationId: string,
    layerType?: Domain.LayerType,
    limit: number = api_default_limit,
    query?: string,
    page: number = api_default_page,
  ): Observable<Domain.Layer[]> {
    let headers = new HttpHeaders({
      situation_id: situationId,
      page: String(page),
      limit: String(limit),
      forceListMSAObjects: 'true',
    });
    if (query) {
      headers = headers.append('query', query);
    }
    if (layerType) {
      headers = headers.append('layerType', layerType);
    }
    return this.http
      .get<Domain.RequestResponse>(`${this.layer_api_path}/situation`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Layer[]));
  }

  getSituationLayers(
    situationId: string,
    layerType?: Domain.LayerType,
    query?: string,
    limit: number = api_default_limit,
    page: number = api_default_page,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({
      situation_id: situationId,
      page: String(page),
      limit: String(limit),
      forceListMSAObjects: 'true',
    });
    if (query) {
      headers = headers.append('query', query);
    }
    if (layerType) {
      headers = headers.append('layerType', layerType);
    }
    return this.http.get<Domain.RequestResponse>(`${this.layer_api_path}/situation`, { headers: headers });
  }

  getLayer(id: string, shared: boolean = false, getLocalAndShared = false): Observable<Domain.Layer | null> {
    let headers = new HttpHeaders({
      id: id,
      limit: String(api_default_limit),
      page: String(api_default_page),
      shared: shared ? 'true' : 'false',
      allowsTrain: 'true',
    });

    if (getLocalAndShared) headers = headers.append('getLocalAndShared', 'true');

    return this.http.get<Domain.RequestResponse>(`${this.layer_api_path}`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Layer;
      }),
    );
  }

  retrieveMissingLayersBoundedToLayer(layersIdsOnSituation: string[], layersAttachedOnLayer: string[]) {
    const headers = new HttpHeaders({
      layersIdsOnSituation: JSON.stringify(layersIdsOnSituation),
      layersAttachedOnLayer: JSON.stringify(layersAttachedOnLayer),
    });

    return this.http
      .get<Domain.RequestResponse>(`${this.layer_api_path}/retrieveMissingLayersBoundedToLayer`, { headers: headers })
      .pipe(
        map((res) => {
          if (res?.data?.length === 0) {
            return null;
          }
          return res.data as Domain.Layer[];
        }),
      );
  }

  getDefaultLightMSALayer(light: string): Observable<Domain.Layer | null> {
    const headers = new HttpHeaders({
      light: light,
      limit: String(api_default_limit),
      page: String(api_default_page),
      shared: 'true',
    });
    return this.http.get<Domain.RequestResponse>(`${this.layer_api_path}`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Layer;
      }),
    );
  }

  getLayerExplorerLayers(
    layerId: string = '',
    folderId: string = '',
    layerType: string = '',
    query?: string,
    limit: number = api_default_limit,
    offset: number = 0,
    cluster: string = 'LIV',
  ): Observable<Domain.Layer[]> {
    let headers = new HttpHeaders({
      offset: String(offset),
      limit: String(limit),
      forceListMSAObjects: 'false',
    });

    if (layerId) {
      headers = headers.append('id', layerId);
    }

    if (folderId) {
      headers = headers.append('folderId', folderId);
      headers = headers.append('haveAFolder', 'true');
    } else {
      headers = headers.append('haveAFolder', 'false');
    }
    if (layerType) {
      headers = headers.append('layerType', layerType);
    }
    if (query) {
      headers = headers.append('query', query);
    }

    headers = headers.append('desc', 'true');
    headers = headers.append('cluster', cluster);

    return this.http.get<Domain.RequestResponse>(`${this.layer_api_path}/v2/list`, { headers: headers }).pipe(
      map((res) => {
        return res.data.docs as Domain.Layer[];
      }),
    );
  }

  getLayerExplorerYaondeLayers(
    query?: string,
    limit: number = api_default_limit,
    offset: number = 0,
    _cluser: string = 'LIV',
  ): Observable<Domain.Layer[]> {
    let headers = new HttpHeaders({
      offset: String(offset),
      limit: String(limit),
      shared: 'true',
    });

    if (query) {
      headers = headers.append('query', query);
    }

    return this.http.get<Domain.RequestResponse>(`${this.layer_api_path}/v2/list`, { headers: headers }).pipe(
      map((res) => {
        return res.data.docs as Domain.Layer[];
      }),
    );
  }

  createLayer(
    layer: {
      Name: string;
      LayerType: Domain.LayerType;
      Description?: string;
      Security: Domain.SecurityType;
      SensitivityLevel: Domain.SensitivityLevel;
      URL?: string;
      Folder?: string;
      Tags?: string[];
      Source?: string;
      SourceType?: Domain.LayerSourceType;
      ImageURL?: string;
      ImageBoundingBox?: number[][];
      MSAObjects_ids?: string[];
      Layers?: string[];
      LogicalQuerySmartLayers?: string;
      SmartRules?: { [key: string]: any | Domain.Criteria }[];
      CustomFields?: { [key: string]: string };
      DefaultPresentation?: {
        Active: boolean;
        Icon: string;
        Color: string;
      };
      ShareWithOutsideYA: boolean;
      ZoneLayer?: boolean;
      GroupLayer?: boolean;
    },
    situationId?: string,
  ): Observable<Domain.Layer> {
    let headers = new HttpHeaders();

    if (layer.Folder) headers = headers.append('Folder', String(layer.Folder));
    if (situationId) headers = headers.append('situationId', situationId);

    return this.http
      .post<Domain.RequestResponse>(`${this.layer_api_path}`, layer, { headers })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  updateLayer(layer: Domain.Layer & { Folder?: string }): Observable<Domain.Layer> {
    let headers = new HttpHeaders();

    if (layer.Folder) headers = headers.append('Folder', String(layer.Folder));

    return this.http
      .put<Domain.RequestResponse>(`${this.layer_api_path}`, layer, { headers })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  moveLayerLibraryFolder(layerId: string, folderId: string): Observable<Domain.Layer> {
    return this.http
      .put<Domain.RequestResponse>(`${this.layer_api_path}/moveLayerLibraryFolder`, { layerId, folderId })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  removeLayerFromLibrary(layerId: string): Observable<any> {
    return this.http.patch<Domain.RequestResponse>(`${this.layer_api_path}/removeFromLibrary`, { layerId });
  }

  updateLayerFavorite(layer: Domain.Layer, isFavorite: boolean): Observable<Domain.Layer> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.layer_api_path}/favorite`, { _id: layer._id, isFavorite: isFavorite })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  cascadedSmartLayers(layerId: string): Observable<Domain.Layer[]> {
    const headers = new HttpHeaders({ layerId });
    return this.http
      .get<Domain.RequestResponse>(`${this.layer_api_path}/cascadedSmartLayers`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Layer[]));
  }

  deleteLayer(id: string, deleteCascadedSmartLayers: boolean): Observable<Domain.Layer> {
    const headers = new HttpHeaders({
      id: id,
      deleteCascadedSmartLayers: String(deleteCascadedSmartLayers),
      limit: String(api_default_limit),
      page: String(api_default_page),
    });
    return this.http
      .delete<Domain.RequestResponse>(`${this.layer_api_path}`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  updateProperties(property: Domain.Property): Observable<Domain.Property> {
    return this.http
      .put<Domain.RequestResponse>(`${this.layer_api_path}/properties`, property)
      .pipe(map((res) => res.data as Domain.Property));
  }

  updateDefaultProperties(property: Domain.MsaObjectDefaultProperties): Observable<Domain.MsaObjectDefaultProperties> {
    return this.http
      .put<Domain.RequestResponse>(`${this.layer_api_path}/defaultProperties`, property)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultProperties));
  }

  createFlag(flag: Domain.MsaObjectDefaultPropertiesFlag): Observable<any> {
    return this.http
      .post<Domain.RequestResponse>(`${this.layer_api_path}/flag`, flag)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultPropertiesFlag));
  }

  updateFlag(flag: Domain.MsaObjectDefaultPropertiesFlag): Observable<any> {
    return this.http
      .put<Domain.RequestResponse>(`${this.layer_api_path}/flag`, flag)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultPropertiesFlag));
  }

  createDefaultProperties(property: Domain.MsaObjectDefaultProperties): Observable<Domain.MsaObjectDefaultProperties> {
    return this.http
      .post<Domain.RequestResponse>(`${this.layer_api_path}/defaultProperties`, property)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultProperties));
  }

  listMsaObjectsProperties() {
    const headers = new HttpHeaders();
    return this.http
      .get<Domain.RequestResponse>(`${this.layer_api_path}/properties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  listProperties() {
    const headers = new HttpHeaders();
    return this.http
      .get<Domain.RequestResponse>(`${this.layer_api_path}/fullProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  listMsaObjectsDefaultProperties(property: string) {
    const headers = new HttpHeaders({ property: property });
    return this.http
      .get<Domain.RequestResponse>(`${this.layer_api_path}/defaultProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }
  layerExportKMZ(layer_id: string) {
    return this.http.get(`${this.layer_api_path}/export`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/kmz').append('layer_id', layer_id),
    });
  }

  layerExportCSV(layer_id: string) {
    return this.http.get(`${this.layer_api_path}/exportCSV`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/zip').append('layer_id', layer_id),
    });
  }

  shareLayer(
    layer_id: string,
    sharingGroups: Domain.SharingGroup[],
    sharingGroupRules: Domain.SharingGroupRule[],
  ): Observable<Domain.Layer> {
    return this.http
      .patch<Domain.RequestResponse>(`${this.layer_api_path}/share`, {
        _id: layer_id,
        SharingGroups: sharingGroups,
        SharingGroupRules: sharingGroupRules,
      })
      .pipe(map((res) => res.data as Domain.Layer));
  }
}
