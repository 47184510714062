import { Injectable } from '@angular/core';
import { SituationEnvironmentEnum } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  private readonly INITIAL_SELECTED_CLUSTER_NAME = 'initcluster';

  setInitialCluster(env: string = SituationEnvironmentEnum.Live) {
    return sessionStorage.setItem(this.INITIAL_SELECTED_CLUSTER_NAME, env);
  }

  getInitialCluster(): SituationEnvironmentEnum {
    return sessionStorage.getItem(this.INITIAL_SELECTED_CLUSTER_NAME) as SituationEnvironmentEnum;
  }

  deleteInitialCluster() {
    sessionStorage.removeItem(this.INITIAL_SELECTED_CLUSTER_NAME);
  }
}
